:root {
  --base_blue_dark: #003A5F;
  --base-blue-light: #36B2FF;
  --base_dark_blue: #006096;
  --text_color_light: #ffffff;
  --text_color_black: #000000;
  --text_color_grey: #999999;
  --app-font-family: 'Open Sans';
  --app-secondary-font-family: 'Verdana,sans-serif';
  --striped-table-color: #E7F3FC;
  --table-plus-btn-bg-color: #006096;
  --table-plus-btn-font-color: #ffffff;
  --placeholder: #2C2C2C;

  /* Variables by color names */
  --color-white: #ffffff;
  --color-black: #000000;
  --transparent: transparent;
  --color-dark-hash: #838383;
  --color-light-hash: #f2f2f2;
  --color-shadow-grey: #757575;
  --color-highlight-navbar-menu: #E5E5E5;
  --color-primary: #006096;
  --color-background-grey: #ebebeb;

  /* Search Bar Color Variables */
  --search-icon-color: #b5aaaa;
  --search-bar-color: #63717f;
  --search-border-color: #dee2e6;
  --search-border-focus-color: #656565;

  /* New Button Color Variables */
  --button-new-bg-color: #006096;
  --button-new-border-color: #006096;
  --button-new-color: #ffffff;
  --button-new-disable-bg-color: #ebebeb;
  --button-new-disable-color: #919191;
  --button-new-icon-border-color: #E0E0E0;
  --button-link-color: #006096;
  --button-light: #ffffff;

  /* Outline button */
  --button-outline-border-color: #006096;
  --button-outline-color: #006096;
  --button-outline-bg: #ffffff;

  /* Tables Color variables */
  --table-head-border: #dee2e6;
  --table-head-color: #838383;
  --table-head-icon-color: #006096;
  --table-active-row-bg: #efefef;
  --table-even-row-bg: #e7f3fc;
  --table-color-success: #c1e77c4d;

  /* Scroll bar Offset */
  --scroll-offset-border-color: #95989A;

  /* Descrepancy Icons Color variable */
  /* un-Submittable */
  --unsubmittable-bg-color: #ff0000;
  --unsubmittable-after-bg-color: #f9cdcf;
  --unsubmittable-after-color: #111111;
  --unsubmittable-after-border-color: #ff6666;
  --unsubmittable-error-border-color: #FF1127;
  --unsubmittable-error-bg: #FADAD7;
  --unsubmittable-column-bg: #FFD196;
  --unsubmittable-border-color: #FF6C0C;

  /* Submittable */
  --submittable-bg-color: #ff8c00;
  --submittable-after-bg-color: #f7dcb7;
  --submittable-after-color: #111111;
  --submittable-after-border-color: #ffb64d;

  /* Modal Field Header */
  --modal-field-header: #757575;
  --modal-odd-bg-color: #E6F3FA;

  /* Dropdown Menu variables */
  --active-dropdown-item: rgba(0, 167, 255, 0.16);

  /* Scrollbar variables */
  --menu-scrollbar-thumb-color: #757575;
  --main-scrollbar-thumb-color: #8D8D8D;

  /* Text color variables */
  --text-shadow-grey-color: #757575;
  --text-btn-link-color: #006096;
  --text-grey-color: #666666;

  /* Navbar text color */
  --navbar_text_color: #FFFFFFCC;

  /* Info Icons */
  --info-icon-color: #757575;
  --summary-filter-border-color: #CCCCCC;

  /* Entry Grid */
  --section-separator-color: #BCBCBC;
  --column-separator-color: #e5e5e5;
  --grid-border-color: #e8e8e8;
}
