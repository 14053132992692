#toast-container > .toast-success {
  background-color: #63A70A;
}
#toast-container > .toast-error {
  background-color: #E63C2F;
}
#toast-container > .toast-warning {
  background-color: #E9A919;
}
#toast-container > .toast-info {
  background-color: #873299;
}

.toast-top-center {
  top: 95px;
  .ngx-toastr {
    width: 350px !important;
  }
}

#toast-container > .toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 24px;
  background-image: none;
  opacity: 1;
  font-size: 12px;
}

button.toast-close-button {
  order: 2;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.toast-message{
  order: 1;
  word-break: break-word;
  margin-right: 7px;
}
