.modal-open .modal {
  overflow-y: hidden;
}

.modal-title {
  color: #212121;
  font-size: 20px;
  font-family: 'opensans-semibold', Verdana, sans-serif;
}
.modal-header, .modal-footer {
  border: 0;
}
.modal-header, .modal-footer, .modal-body {
  padding: 0;
}

.modal-content {
  padding: 20px;
}

//@mixin ngbStModalMixin() {
//  .modal-title {
//    color: #212121;
//    font-size: 20px;
//    font-family: 'opensans-semibold', Verdana, sans-serif;
//  }
//  .modal-header, .modal-footer {
//    border: 0;
//  }
//  .modal-header, .modal-footer, .modal-body {
//    padding: 0;
//  }
//
//  .modal-content {
//    padding: 20px;
//  }
//}
//
//.delete-timecard-warning {
//  @include ngbStModalMixin();
//}

.timecard-to-batch-modal {
  .col-md-4:first-child, .col-md-12 {
    padding-left: 0;
  }
  & .modal-dialog {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  & .modal-header {
    padding-bottom: 1.5rem;
    border: 0;
  }
  & .modal-content {
    width: 730px;
    height: 447px;
  }
  & .modal-body {
    height: 270px;
  }
  & .modal-footer {
    margin-top: 3rem;
    border: 0;
    justify-content: space-between;
    button {
      z-index: 10;
    }
  }
}

.duplicate-batch {
  & .modal-dialog {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  & .modal-content {
    width: 620px;
    height: 315px;
  }
}

.duplicate-multiple-batch {
  & .modal-dialog {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  & .modal-content {
    width: 600px;
    height: 452px;
  }
}

.work-location-picker-modal {
  & .modal-content {
    width: 600px;
  }
}

.split-coding {
  & .modal-content {
    width: 1050px;
    height: 375px;
    margin-top: 30px;
    border-radius: 6px;
  }
}

.work-location-picker-modal {
  & .modal-content {
    width: 600px;
  }
  th,td {
    padding-left: 0 !important;
    min-height: 30px;
  }
  .modal-body {
    padding: 15px 0;
  }
}

.error-timecard {
  & .modal-content {
    width: 600px;
  }
}

.upload-modal {
  & .modal-dialog {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  & .modal-content {
    max-width: 730px;
  }
}
.summary-filter-modal {
  & .modal-dialog {
    display: flex;
    justify-content: center;
    width: 220% !important;
    max-width: 90%;
  }
}
.release-modal {
  & .modal-dialog {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  & .modal-content {
    max-width: 1030px !important;
  }
}

.split-coding-warning, .split-coding-error {
  height: 100%;
  display: contents;
  .modal-header {
    padding-bottom: 30px;
  }
}

.st-warning-modal {
  .modal-body {
    padding: 25px 0 20px 0;
  }
  .modal-header, .modal-footer {
    color: #212121;
    border-bottom: 0;
  }
  .modal-footer {
    border-top: 0;
  }
}
