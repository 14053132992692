/* You can add global styles to this file, and also import other style files */
@import "scss/theme";
//@import "scss/browse-page";
@import "scss/custom-ngx-toastr";
@import "scss/buttons";
@import "scss/custom-ngbootstrap-modal";
@import "scss/custom-ngbootstrap-calendar";
@import "scss/font-size-helper";
@import "scss/flex-box-helper";
@import "../node_modules/@angular/material/legacy-prebuilt-themes/legacy-indigo-pink.css";
@import "scss/custom-material-style";

html {
  font-size: 10px;
}

body {
  font-size: 14px;
  font-family: var(--app-font-family), var(--app-secondary-font-family);
}

.st-text-white {
  color: var(--text_color_light);
}

.st-cursor-text {
  cursor: text;
}
table
{
  width: 100%;
}
.st-cursor-pointer {
  cursor: pointer;
}

.st-cursor-na{
  cursor: not-allowed;
}
.st-invisible {
  visibility: hidden;
}
.hot-key {
  float: right;
  color: #757575;
}
.inject-overriddenReason {
  height: 150px;
  width: 400px;
}

.red-color {
  color: red;
}
.st-quick-action .dropdown-item:not(:disabled):focus, .st-quick-action .dropdown-item:not(:disabled):hover {
  color: rgba(33, 33, 33, 1);
  background-color: rgba(0, 167, 255, 0.16);
}

@media (min-width: 1200px) {
  .container {
    max-width: 1500px;
  }
}

@media (min-width: 991px) and (max-width: 1299px) {
  .container {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 768px;
    padding: 0;
  }
  .st-btn-transparent {
    font-size: 12px;
  }
  .st-logo-container {
    width: 18%;
  }
}

/*font style start*/

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/OpenSans-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'opensans-regular';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/OpenSans-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'opensans-semibold';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/OpenSans-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'opensans-lightitalic';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/OpenSans-LightItalic.ttf') format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/HelveticaNeue-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/HelveticaNeue-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/HelveticaNeue-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Verdana';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/Verdana.ttf') format('opentype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/fonts/Roboto-Regular.ttf') format('truetype');
}

.st-font-os-b {
  font-family: 'OpenSans-Bold', Verdana, sans-serif;
}

.st-font-os-sb {
  font-family: 'opensans-semibold', Verdana, sans-serif;
}

.st-font-os-r {
  font-family: 'opensans-regular', Verdana, sans-serif;
}

.st-font-hn-m {
  font-family: 'HelveticaNeue-Medium', Verdana, sans-serif;
}

.st-font-hn-b {
  font-family: HelveticaNeue-Bold, Verdana, sans-serif;
}

.st-font-hn-r {
  font-family: HelveticaNeue-Light, Verdana, sans-serif;
}

.st-font-vd {
  font-family: 'Verdana';
}

/*font style end*/

/* Color style */

.st-c-blue-dark {
  color: #006096;
}

.st-c-black {
  color: #000000;
}

/*Color style end*/

.st-scroll ,.st-scrollable-sec {
  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;

    &-thumb {
      background-color: #8D8D8D;
    }

    &-track {
      background-color: #FFFFFF;
      border: 1px solid #95989A;
    }

    &-track-piece:vertical:start {
      border-top: 1px solid #dee2e6;
    }
  }
}

// helper classes
.pl-23 {
  padding-left: 23px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-17{
  padding-left: 17px;
}
.ps-12{
  padding-left: 12px;
}
.ml-4rem {
  margin-left: 4rem;
}

.ml-2rem {
  margin-left: 2rem;
}

.st-ml-2p3 {
  margin-left: 1.3rem;
}

.ml-5rem {
  margin-left: 5rem;
}

.st-w-6 {
  width: 6%;
}

.st-w-15 {
  width: 15%;
}

.st-w-13 {
  width: 13%;
}

.st-w-18 {
  width: 18%;
}

.st-w-24 {
  width: 24px !important;
}

.st-w-53 {
  width: 53px !important;
}

.st-w-34 {
  width: 34px !important;
}

.st-w-36 {
  width: 36px !important;
}

.st-w-51 {
  width: 51px !important;
}

.st-w-62 {
  width: 62px !important;
}

.st-w-66 {
  width: 66px !important;
}

.st-w-56 {
  width: 56px;
}

.st-w-198 {
  width: 198px;
}

.st-w-118 {
  width: 118px;
}

.st-w-85 {
  width: 85px;
}

.st-w-50-90 {
  min-width: 50px;
  max-width: 90px;
}

.st-mw-55{
  min-width: 55px;
}
.st-w-150 {
  width: 150px;
}

.pl-10 {
  padding-left: 10px;
}

.st-plpx-24 {
  padding-left: 24px;
}

.st-zi-1 {
  z-index: 1;
}

.st-text-muted {
  color: #757575;
}
//helper classes ends
input[type="checkbox"],
button{
  cursor: pointer;
}
input[type="checkbox"]:disabled,
select:disabled,
button:disabled{
  cursor: not-allowed;
}

[hidden]{
  display: none !important;
}

.hide-body-overflow {
  overflow-y: hidden !important;
}
.show-overflow {
  overflow-y: scroll !important;
}

.st-btn-disabled,
.st-btn-disabled:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: #919191;
  cursor: not-allowed;
}

.dropdown-item:disabled {
  pointer-events: all;
}

.btn.disabled, .btn:disabled {
  opacity: 1 !important;
}

.st-bc-gray-1 {
  background-color: #f5f5f5;
}

.st-banner {
  width: 100%;
  height: 25px;
  font-size: 14px;
  padding-left: 10px;
  color: white;
  .st-lock-icon::after {
    content: '\F023';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    font-style: normal;
    font-size: 18px;
  }
  .st-warning-icon::after {
    content: '\f06a';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    font-style: normal;
    font-size: 18px;
  }
  .st-banner-label {
    font-weight: bold;
    vertical-align: text-bottom;
  }
  .st-banner-description {
    margin-left: 25px;
    font-size: 13px;
    vertical-align: text-bottom;
  }
  &-locked {
    background: red;
  }
  &-warning {
    background: #f68d31;
  }
  &.pl {
    padding-left: 2.25rem;
  }
  &.batch-details-warning {
    height: 28px;
  }
}

//.st-banner {
//  width: 100%;
//  height: 25px;
//  font-size: 14px;
//  padding-left: 10px;
//  color: white;
//  .st-banner-label {
//    font-weight: bold;
//    vertical-align: text-bottom;
//  }
//  .st-banner-description {
//    margin-left: 25px;
//    font-size: 13px;
//    vertical-align: text-bottom;
//  }
//  .st-banner-warning-icon::after {
//    content: '\F12a';
//    font-family: 'FontAwesome';
//    font-style: normal;
//    font-size: 15px;
//    color: #f68d31;
//  }
//  &-warning-icon-wrap {
//    display: inline-flex;
//    width: 15px;
//    height: 21px;
//    background: #ffff;
//    border-radius: 4px;
//    justify-content: center;
//    align-items: center;
//    background-color: white;
//  }
//}
//.st-banner-warning-bg {
//  background: #f68d31;
//  padding-left: 5px;
//}

fieldset:disabled {
  cursor: not-allowed;
  input {
    cursor: not-allowed;
  }
  .mat-checkbox, select {
    cursor: not-allowed;
  }
}

.grid-disabled {
  background-color: #9191911a;
  opacity: .5;
  .st-btn-tertiary-sm {
    border-color: transparent;
    background-color: transparent;
  }
}

.st-scrollable-sec {
  & {
    display: flex;
    flex: 1;
    overflow-x: scroll;
    width: 1vw;
  }

  .header {
    display: flex;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #757575;
    height: 12px;
  }

  &::-webkit-scrollbar {
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-top: 2.2px solid transparent;
    border-bottom: 2.2px solid transparent;
    background-clip: padding-box;
  }
}

.mr-1p {
  margin-right: 1px;
}

/**
loader styles:
 */

.loader-overlay {
    -ms-opacity: 0.9;
  background: #444;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: fixed;
  top: 0;
  vertical-align: middle;
  width: 100%;
  z-index: 100000;
}

.loader-content {
  margin-left: auto;
  margin-top: auto;
  width: 50%;
}

.loader-center {
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  left: 50%;
  display: block;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -55%);
}

.loader-text {
  color: #FFF;
  font-size: 18px;
  height: 50%;
}

.st-button-new-line {
  color: #006096;
  font-size: 12px;
  text-decoration: none;
}

.st-button-new-line:disabled {
  background: #FFFFFF !important;
}
.st-button-new-line:active,.st-button-new-line:focus {
  color: #006096;
}

a.disabled {
  pointer-events: none;
}
.disabled-custom-checkbox{
  opacity: .5;
  cursor: not-allowed;
}
.st-ssn {
  margin-bottom: 0.85rem;
  font-size: 16px;
  font-weight: normal;
  margin-left: .5rem!important;
  align-self: flex-end!important;
}

st-batch-details-header {
  .st-quick-action-label {
    font-size: 18px !important;
  }
}

st-settings-details {
  define-drawer-inner-container {
    overflow-x: hidden !important;
  }
}
st-daily-time-grid, st-day-cost-report-grid,
st-sag-daily-time-grid {
  .st-quick-action {
    top: 1px !important;
    bottom: 0 !important;
  }
  .st-quick-action .dropdown-menu {
    min-width: 21rem !important;
  }
  define-form-field-infix{
    margin-top: -15px !important;
  }
  st-create-new-mask .crate-new-mask {
    background: none !important;
    position: initial !important;
  }
}
st-daily-time, st-day-cost-report,
st-sag-daily-time {
  st-quick-action-menu  .dropdown-menu  {
    min-width: 21rem !important;
  }
}
@media screen and (max-height: 700px)  {
  st-create-new-mask .crate-new-mask-content {
   top:65% !important;
  }
}
@media screen and (max-height: 550px)  {
  st-create-new-mask .crate-new-mask-content {
    top:77% !important;
  }
}
.dt-split-text-box{
  border: 0;
  outline: none;
  background-color: inherit;
  width: 100%;
  text-align: center;
}
st-daily-time-grid, st-day-cost-report-grid,
st-sag-daily-time-grid {
  st-crew-picker input{
    font-size: 14px !important;
  }
  ngb-typeahead-window[id^="ngb-typeahead-"] {
    position: fixed !important;
    top: 10px !important;
    left: 0 !important;
  }
.no-bulk-input {
  ngb-typeahead-window[id^="ngb-typeahead-"] {
    top: 300px !important;
  }
}
}

.sticky-header {
  background: #fff;
  position: sticky;
  z-index: 100;
  display: inherit;
  width: 100%;
  height: 40px;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  color: #838383;
  font: 14px "opensans-regular", Verdana, sans-serif;

  .d-inline-block {
    padding-top: 10px;
  }
  th {
    font-weight: 100;
  }
}

.summary-filter-items {
  tbody tr:nth-child(odd) {
    background: var(--table-even-row-bg) !important;
  }
}

.st-mt-75 {
  margin-top: .75rem;
}
// splash screen styles for non-chrome browsers
.download-chrome {
  display: none;
  position: relative;
  width: 100vw;
  height: 100vh;
  background: var(--color-light-hash);
  font: 600 14px var(--app-font-family), var(--app-secondary-font-family);

  &.show-download-chrome{
    display: block;
  }
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 42px;
    padding-left: 15px;
    background: var(--base_blue_dark);

    img {
      width: 116px;
    }
  }
  .content {
    text-align: center;
    padding-top: 200px;

    p {
      margin-bottom: 25px;
    }
    img {
      display: block;
      width: 55px;
      height: 55px;
      margin: 40px auto 0;
    }
  }
}
.st-browse-grid-wrap {
  .table>:not(caption)>*>* {
    background: #0000 !important;
  }
}
