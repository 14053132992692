:root {
  --st-blue: #006096;
  --white: #FFFFFF;
  --blue: #009ABF;
  --disabled-text: #919191;
  --disabled-gray: #EBEBEB;
}
/*
* Note: The button spec we are following are defined here https://confluence.ep.com/display/UE/Buttons
* Tech note:  use it along with bootstrap btn class .btn
**/
@mixin st-button($color, $background-color, $border-color) {
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 2px;
  color: $color;
  background-color: $background-color;
  border-color: $border-color;
  &:hover,
  &:focus,
  &:active {
    color: $color;
    background-color: $background-color;
    border-color: $border-color;
  }
}

@mixin box-shadow() {
  box-shadow: 0 2px 2px #00000066;
}

@mixin size($font-size, $height, $padding-right-left, $margin-right-left) {
  font-size: $font-size;
  height: $height;
  padding: 0 $padding-right-left;
  margin: 0 $margin-right-left;
}

.st-btn-primary {
  &-sm {
    @include st-button(var(--white), var(--st-blue), var(--st-blue));
    @include size(14px, 27px, 12px, 7.5px);
    @include box-shadow();
    &-disabled, &:disabled {
      @include st-button(var(--disabled-text), var(--disabled-gray), var(--disabled-gray));
      @include size(14px, 27px, 12px, 7.5px);
    }
  }

  &-md {
    @include st-button(var(--white), var(--st-blue), var(--st-blue));
    @include size(16px, 36px, 16px, 7.5px);
    @include box-shadow();
    &-disabled, &:disabled {
      @include st-button(var(--disabled-text), var(--disabled-gray), var(--disabled-gray));
      @include size(16px, 36px, 16px, 7.5px);
    }
  }

  &-lg {
    @include st-button(var(--white), var(--st-blue), var(--st-blue));
    @include size(16px, 42px, 18px, 7.5px);
    @include box-shadow();
    &-disabled {
      @include st-button(var(--disabled-text), var(--disabled-gray), var(--disabled-gray));
      @include size(16px, 42px, 18px, 7.5px);
    }
  }
}

.st-btn-secondary {
  &-sm {
    @include st-button(var(--st-blue), var(--white), var(--st-blue));
    @include size(14px, 27px, 12px, 7.5px);
    @include box-shadow();
  }

  &-md {
    @include st-button(var(--st-blue), var(--white), var(--st-blue));
    @include size(16px, 36px, 16px, 7.5px);
    @include box-shadow();
    &:disabled {
      @include st-button(var(--disabled-text), var(--white), var(--st-blue));
      @include size(16px, 36px, 16px, 7.5px);
    }
  }

  &-lg {
    @include st-button(var(--st-blue), var(--white), var(--st-blue));
    @include size(16px, 42px, 18px, 7.5px);
    @include box-shadow();
  }
}

.st-btn-tertiary {
  &-sm {
    @include st-button(var(--blue), var(--white), var(--white));
    @include size(14px, 27px, 12px, 6px);
  }

  &-md {
    @include st-button(var(--blue), var(--white), var(--white));
    @include size(16px, 36px, 16px, 6px);
    &-disabled {
      @include st-button(var(--disabled-gray), transparent, transparent);
      @include size(16px, 36px, 6px, 0px);
    }
  }

  &-lg {
    @include st-button(var(--blue), var(--white), var(--white));
    @include size(16px, 42px, 18px, 6px);
  }
}

.st-btn-transparent {
  &-md {
    @include st-button(var(--blue), transparent, transparent);
    @include size(16px, 36px, 16px, 6px);
  }
}

.st-btn-link {
  color: var(--st-blue);
  text-decoration: underline;
  background-color: inherit;
  font-size: 14px;
  &-active {
    color: #000;
    font-size: 14px;
    text-decoration: none;
  }
}

@mixin st-link-button() {
  color: var(--st-blue);
  text-decoration: none;
  background-color: inherit;
  text-transform: uppercase;
  border-color: transparent;
  &:hover,
  &:focus{
    color: var(--st-blue);
    text-decoration: underline;
    border-color: transparent;
  }
}

.st-link-btn {
  &-sm {
    @include st-link-button();
    @include size(14px, 27px, 0, 0);
    &-text-transform-0 {
      @include st-link-button();
      @include size(14px, 27px, 0, 0);
      text-transform: none;
    }
  }
  &-md {
    @include st-link-button();
    @include size(16px, 36px, 0, 0);
    &-text-transform-0 {
      @include st-link-button();
      @include size(16px, 36px, 0, 0);
      text-transform: none;
    }
  }

  &-lg {
    @include st-link-button();
    @include size(16px, 42px, 0, 0);
    &-text-transform-0 {
      @include st-link-button();
      @include size(16px, 42px, 0, 0);
      text-transform: none;
    }
  }
}

.btn-link {
  text-decoration: none;
}
