.mat-form-field-infix {
  width: 100% !important;
}

.mat-tab-label-container {
  height: 52px;
}

.mat-tab-label {
  opacity: 1 !important;

  .mat-tab-label-content {
    font-size: 18px;
    color: #000000;
    opacity: 1;
    font-family: 'opensans-regular', Verdana, sans-serif;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ffc021;
}

.mat-ink-bar {
  height: 4px !important;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #63B72F;
}

.mat-checkbox-checkmark-path {
  stroke-width: 3.13333px !important;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #006096;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #006096;
}

.mat-checkbox-disabled {
  cursor: not-allowed !important;
}
.st-template-picker{
  .mat-form-field{
    line-height:1;
  }
  .mat-form-field-infix{
    border-top:0px;
  }
  .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
    color: #212121;
  }
  .mat-form-field-disabled .mat-form-field-underline{
    background-color: #212121 !important;
  }
}
