//calendar material styles:
.ngb-dp-day > .btn-light {
  border-radius: 2.5rem;
  outline: none;
}
.ngb-dp-day > .bg-primary {
  background-color: #125c7c !important;
}
.ngb-dp-weekday {
  color: black !important;
  font-style: normal !important;
  width: 3.5rem !important;
  font-size: 100% !important;
}
.ngb-dp-day:focus {
  outline: none;
}
.ngb-dp-months {
  font-size: 1.2rem;
}
.ngb-dp-weekdays {
  border-bottom: none !important;
  background-color: #fff !important;
}
.ngb-dp-header {
  background-color: #fff !important;
  padding-top: .8rem !important;
  padding-bottom: 1.2rem;
}
.ngb-dp-arrow-btn {
  color: black !important;
}
.ngb-dp-month-name {
  color: #125c7c !important;
  font-weight: 700;
  font-size: 1.4rem !important;
}

// calendar size:
.ngb-dp-day {
  width: 3.5rem !important;
  height: 3.5rem !important;
}
.ngb-dp-day > div {
  width: 3.5rem !important;
  height: 3.5rem !important;
  line-height: 3.5rem !important;
}
ng-b-datepicker {
  font-size: 1.3rem;
}

.st-week-end-picker + ngb-datepicker {
  left: -23px !important;
}

.newtc-datepicker ngb-datepicker {
  left: -33px !important;
}
